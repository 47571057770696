import Page from "../Page";

export default function Testimonials(){
    return <Page id="testimonials">
            <div className="about">
                <div className="page-content">
                    <h1>Testimonials</h1>


                    <div className="row">
                        <div className="column left-large">
                        <div className='stars'>★★★★★</div>
                        <p>As the CEO of Steve Burnstead Construction LLC , I am very excited to offer both my personal and professional recommendation to anyone seeking Real Estate professional services from Dori Hanson.</p> 

                        <p>Over the last 14 years Dori has provided our home building company SBC LLC, with multiple single family redevelopment opportunities in the greater Bellevue, Wa  real estate marketplace.  Dori's RE services ranged from negotiations of the RE contract with the initial property owner to our Listing Agent and Sales of the homes for each land parcel she secured for SBC LLC.</p>

                        <p>Dori's knowledge of the Bellevue RE market and her ability to work both with our home purchasers and the SBC Construction team was invaluable.</p> 

                        <p>Dori provides a wealth of knowledge and experience in the Real Estate industry .  Dori also always brings that rare constant positive attitude, as well as important timely and diligent communication with her clients.</p>

                        <p>Anyone would be fortunate to utilize Dori for Real Estate services.</p>
                            <div className='client-name'> - Steve Burnstead</div>
                        </div>
                    </div>                     

                    <div className="row">
                        <div className="column left-large">
                        <div className='stars'>★★★★★</div>
                        <p>I am writing this letter with the hope that others can experience the joy and loyalty that I have experienced while working with Dori Hanson.  She has represented me in many real estate transactions, both on the listing side,  the buying side and land acquisition.</p>  
                    
                        <p>I am currently the owner of Think It LLC and the past owner of Parkwood Homes and Four Suns Inc for decades..  I am ranked one of the top 15 builders in the Puget Sound area and have done well over 500 real estate transactions. I have worked with many real estate agents over the years and Dori is one of the best I have ever worked with.  She is professional, hardworking, knowledgeable, experienced and straight forward.</p>  
                        
                        <p>With Dori I always feel that my best interests were always being looked after.  She has represented me with honesty and integrity, which is the way I want to be represented.  I would refer her to anyone that wants to be represented in this manner.  When I have referred Dori of the Mana Ohana Team, the people that I refer her to always call me back to thank me, which makes it extremely easy to continue to refer her.  I highly recommend Dori and her team.</p>
                            <div className='client-name'> - Steve Holzknecht, Member Master Builders Association</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column left-large">
                        <div className='stars'>★★★★★</div>
                            Dori was super helpful throughout the property purchase process. Dori was able to find me a property before it was listed and helped us finding the right house. She was quite professional in executing this deal - more importantly, she was extremely responsive, and was available at odd hours, weekends, etc. It was an amazing experience of working with Dori.. I wish her all the luck.. Tx.
                            <div className='client-name'> - Janmesh</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column left-large">
                            <div className='stars'>★★★★★</div>
                                Dori was super helpful and responsive throughout the listing and selling process. Keen eye for detail and makes sure to take care of the property (example: one bulb dimmer than the other, cleaning the patio railings, and window shades, etc). She addressed buyer and buyer agent questions really quickly, all day long. She was kind enough to correspond with the HOA to get help whenever needed. If I were to sell another property in the Seattle area, I would definitely sign up Dori again as my agent.  
                                <div className='client-name'> - Rahul</div>               
                            </div>
                    </div>
                    <div className="row">
                        <div className="column left-large">
                            <div className='stars'>★★★★★</div>
                                Dori is someone who you feel that she's on your team & will do whatever she can to help you sell/buy your home. She helped us purchase a new build & we loved her customer service so she is also selling our previous home. She markets very aggressively & easy to work with. We love her! 
                            <div className='client-name'>- Gianina</div> 
                        </div>
                    </div>

                    <div className="row">
                        <div className="column left-large">
                            <div className='stars'>★★★★★</div>
                            Just the very best!!! We liked a short sale property and the deal stalled for over 14 months. Dori was committed to seeing it through and completely surpassed our expectation on advocating for our best interest as buyers. Her perseverance led us down the path of closing on a seemingly impossible transaction in June 2017. Atop, she is very knowledgeable of the local market, thoughtful in making recommendations keeping her client's interest in mind, has a wide network of contractors and resources and one who doesn't limit herself to any boundaries when it comes to extending her help both when transaction is ongoing as well as after its closed.
                        <div className='client-name'>- Puneet</div> 
                        </div>
                    </div>

                    <div className="row">
                        <div className="column left-large">
                            <div className='stars'>★★★★★</div>
                            This is our second time working with Dori and we could not have asked for a better person to help us with our home purchase. We have many friends who have used her services as well and share the same sentiment. She is very patient during the whole search process and never forces you to make any decision against your will. She will discuss all the pros and cons of the property and help you in every step till (and after) closing with honest feedback on what should be done in any given situation and who should be contacted. She is very knowledgeable and a great person to work with.
                            <div className='client-name'>- Saurabh</div> 
                        </div>
                    </div>
                </div>
            </div>
            </Page> 
}